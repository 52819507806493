<template>
  <div>
    <b-card v-if="details">
      <h2 class="mb-2 text-primary">
        {{ $t('Process details') }}
      </h2>
      <hr>
      <b-row>
        <b-col
          md="6"
          lg="3"
          cols="12"
        >
          <h5 class="mb-1">
            {{ $t('name') }}
          </h5>
          <p>
            {{ details.user_name || '-' }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="6"
          lg="3"
          cols="12"
        >
          <h5 class="mb-1">
            {{ $t('labels.module_name') }}
          </h5>
          <p>
            {{ details.model_name_value || '-' }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="6"
          lg="3"
          cols="12"
        >
          <h5 class="mb-1">
            {{ $t('Module number') }}
          </h5>
          <p>
            {{ details.module_id || '-' }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="6"
          lg="3"
          cols="12"
        >
          <h5 class="mb-1">
            {{ $t('labels.events') }}
          </h5>
          <p>
            {{ details.event_value || '-' }}
          </p>
          <hr>
        </b-col>
        <b-col
          md="6"
          lg="3"
          cols="12"
        >
          <h5 class="mb-1">
            {{ $t('labels.created_on') }}
          </h5>
          <p>
            {{ details.created_at || '-' }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        md="6"
        cols="12"
      >
        <b-card v-if="details">
          <h2 class="mb-2 text-primary">
            {{ $t('old value') }}
          </h2>
          <hr>
          <b-row>
            <b-col
              cols="12"
            >
              <b-row
                v-for="(item, key) in details.old_values"
                :key="key"
              >
                <b-col
                  md="5"
                  cols="12"
                >
                  <h5 class="mb-0 text-warning">
                    {{ $t(key) }}
                  </h5>
                </b-col>
                <b-col
                  md="7"
                  cols="12"
                >
                  <p
                    v-if="Array.isArray(item)"
                    style="gap: 10px"
                    class="d-flex mb-0"
                  >
                    <span
                      v-for="(el, i) in item"
                      :key="i"
                    >
                      <b-badge
                        pill
                        variant="light-info"
                        style="min-width: 50px;"
                      >{{ $t(el) }}</b-badge>
                    </span>
                  </p>
                  <p
                    v-else
                    class="mb-0"
                  >
                    {{ item || '-' }}
                  </p>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        md="6"
        cols="12"
      >
        <b-card v-if="details">
          <h2 class="mb-2 text-primary">
            {{ $t('new value') }}
          </h2>
          <b-row v-if="details.new_values && details.new_values.length">
            <b-col
              cols="12"
            >
              <b-row
                v-for="(item, key) in details.new_values"
                :key="key"
              >
                <b-col
                  md="5"
                  cols="12"
                >
                  <h5 class="mb-0 text-warning">
                    {{ $t(key) }}
                  </h5>
                </b-col>
                <b-col
                  md="7"
                  cols="12"
                >
                  <p
                    v-if="Array.isArray(item)"
                    style="gap: 10px"
                    class="d-flex mb-0"
                  >
                    <span
                      v-for="(el, i) in item"
                      :key="i"
                    >
                      <b-badge
                        pill
                        variant="light-info"
                        style="min-width: 50px;"
                      >{{ $t(el) }}</b-badge>
                    </span>
                  </p>
                  <p
                    v-else
                    class="mb-0"
                  >
                    {{ item || '-' }}
                  </p>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <p
            v-else-if="details.event === 'updated' || details.event === 'deleted'"
            class="mb-0"
          >
            {{ $t('The record has been deleted') }}
          </p>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      details: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.axios.get(`/audits/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
        })
    },
  },
}
</script>
